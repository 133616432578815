<template>
  <div>
    <div class="top-bg-container" :style="{ backgroundImage: `url(${bgSrc})` }">
      <div class="divider"></div>
      <div class="text_container">
        <div class="title">{{ title }}</div>
        <slot></slot>
      </div>
      <slot name='btn'></slot>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bgSrc: ''
    }
  },
  created () {
    this.iniBgImg()
  },
  methods: {
    iniBgImg () {
      // eslint-disable-next-line
      this.bgSrc = require(`@/assets/bg/${this.bgName}`)
    }
  },
  props: {
    title: String,
    bgName: String
  }
}
</script>

<style scoped>
.top-bg-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 512px;
  box-sizing: border-box;
  padding-top: 200px;
  position: relative;
}
.divider {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  position: absolute;
  top: 30%;
  left: calc(50% - 50px);
}
.title {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 56px;
  margin-top: 20px;
  width: 100%;
  text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.text_container{
  position: absolute;
  width: 60em;
  top: 35%;
  left: calc(50% - 30em);
}
@media only screen and (max-width: 768px) {
.top-bg-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30vh;
  box-sizing: border-box;
  padding-top: 10vh;
}
.divider {
  margin: 0 auto;
  box-sizing: border-box;
  width: 20vw;
  height: 1.2vw;
  background: #bc9a41;
  border-radius: 4px;
  top: 30%;
  left: calc(50% - 10vw);
}
.title {
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 1.2rem;
  margin-top: 4vw;
  text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
}
.text_container{
  position: static;
  width: 100%;
  text-align: center;
}
}
</style>
